define([
    'lodash',
    'react',
    'coreUtils',
    'prop-types',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixAdsResponsiveCss'
], function (_, React, coreUtils, PropTypes, santaComponents, translationsUtils, wixAdsCss) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;
    function getLogo(title) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="57" height="12" viewBox="0 0 57 12" role="img" aria-label="${title}">` +
        '<g fill-rule="evenodd">' +
        '<path d="M0 .421L0 10.421 7.034 10.421 7.034 8.827 1.887 8.827 1.887 6.156 6.769 6.156 6.769' +
        ' 4.561 1.887 4.561 1.887 2.016 7.034 2.016 7.034.421zM10.42 6.897c0 .643.188 1.172.566 1.587.378.415.874.622' +
        ' 1.49.622.448 0 .844-.111 1.19-.335.344-.225.586-.522.726-.895v-.923c0-.691-.187-1.24-.559-1.644-.373-.406-.' +
        '849-.608-1.428-.608-.596 0-1.076.207-1.44.622-.363.415-.545.939-.545 1.574zm5.82 3.524h-1.833v-.895c-.243.346-' +
        '.567.617-.973.818-.406.2-.874.3-1.405.3-.653 0-1.245-.158-1.777-.475-.53-.316-.956-.757-1.273-1.322-.317-.563-.' +
        '476-1.214-.476-1.95 0-.737.16-1.386.476-1.944.317-.56.742-.998 1.273-1.315.532-.318 1.12-.476 1.762-.476.541 0' +
        ' 1.012.104 1.413.314.401.21.723.492.965.847V.35h1.847v10.07zM18.14 10.421h1.847V3.385H18.14v7.036zm.924-8.253c-.317' +
        ' 0-.583-.104-.797-.314-.215-.21-.322-.464-.322-.763 0-.308.107-.566.322-.776.214-.209.48-.315.797-.315.317 0' +
        ' .582.106.797.315.214.21.322.468.322.776 0 .299-.108.553-.322.763-.215.21-.48.314-.797.314zM26.463 10.421h-' +
        '1.762c-1.279 0-1.917-.639-1.917-1.917V4.882h-1.23V3.385h1.23v-1.86h1.847v1.86h1.748v1.497h-1.748v3.301c0' +
        ' .484.232.727.7.727h1.132v1.511zM31.485 9.106c.596 0 1.077-.204 1.441-.615.363-.41.545-.943.545-1.594' +
        ' 0-.645-.182-1.171-.545-1.581-.364-.41-.845-.615-1.44-.615-.589 0-1.065.205-1.429.615-.363.41-.545.936-.545' +
        ' 1.58 0 .652.182 1.185.545 1.595.364.41.84.615 1.428.615m0 1.539c-.756 0-1.428-.16-2.015-.482-.587-.323-1.047' +
        '-.765-1.377-1.33-.33-.563-.497-1.21-.497-1.936 0-.728.166-1.374.497-1.937.33-.565.79-1.005 1.377-1.322.587-.318' +
        ' 1.26-.476 2.015-.476.764 0 1.441.158 2.028.476.587.317 1.047.757 1.377 1.322.332.563.497 1.209.497 1.937 0' +
        ' .727-.165 1.373-.497 1.937-.33.564-.79 1.006-1.377 1.329-.587.322-1.264.482-2.028.482M41.417 3.259v1.679h-' +
        '.378c-.653 0-1.175.194-1.567.581-.391.386-.588 1.018-.588 1.895v3.008h-1.846V3.384h1.833V4.77c.158-.382.363-' +
        '.683.615-.903.252-.218.525-.375.819-.468.293-.093.58-.14.86-.14h.252zM48.1.421L45.5.421 49.199 5.421 45.5 10.421' +
        ' 48.1 10.421 51.8 5.421zM52.578 6.472L51.278 8.229 52.9 10.421 55.5 10.421zM52.578 4.37L55.5.421 52.9.421 51.278' +
        ' 2.613z" transform="translate(.5 .579)"/></g></svg>';
    }
    function getLogoPreview(title) { 
        return `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" role="img" aria-label="${title}">` +
        '<g fill-rule="evenodd"><path d="M2.6 0L0 0 3.699 5 0 10 2.6 10 6.3 5zM7.077 6.051L5.777 7.808 7.399 10 9.999 10zM7.077 3.949L9.999 0 7.399 0 5.777 2.192z"/></g></svg>';
    }
    const logoPlaceHolder = '{X}';
    
    function getBannerProps(isPreview, href, wixAdsId, overlay) {
        const classes = ['desktop-top'];

        const props = {
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`
        };

        if (isPreview) {
            classes.push('preview');
        }

        if (!overlay) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }

        props.className = classes.join(' ');

        return props;
    }

    function getTextHTML(adsTranslation, isPreview) {
        const text = isPreview ? adsTranslation.responsiveBannerTextPreview : adsTranslation.responsiveBannerText;
        const logoContent = `<span class="logo">${isPreview ? getLogoPreview(adsTranslation.logoTextPreview) : getLogo(adsTranslation.logoText)}</span>`;
        const resultHtml = text.replace(logoPlaceHolder, logoContent);
        return resultHtml;
    }

    function getWixAdsElement(props) {
        const {wixAdsId, isPreview, metaSiteId, translations, overlay} = props;

        if (overlay) {
            return createReactElement('div',
                getBannerProps(isPreview, null, wixAdsId, overlay));
        }

        const adsTranslation = translationsUtils.getWixFreemiumTranslations(translations, isPreview, metaSiteId);
        const bannerUrl = isPreview ? `${adsTranslation.responsiveBannerUrlPreview}&siteGuid=${metaSiteId}` : adsTranslation.responsiveBannerUrl;

        return createReactElement('a', getBannerProps(isPreview, bannerUrl, wixAdsId),
            createReactElement('span', {className: 'contents'}, [
                createReactElement('span', {key: 'responsiveBannerText', className: 'text', dangerouslySetInnerHTML: {__html: getTextHTML(adsTranslation, isPreview)}}),
                isPreview ? createReactElement('span', {key: 'responsiveBannerButton', className: 'button'}, adsTranslation.responsiveBannerButton) : null
            ]));
    }

    class BannerResponsive extends React.Component {
        render() {
            const {wixAdsId, isWixAdsAllowed, direction, overlay} = this.props;

            const wrapperClasses = {
                'wix-ads-responsive': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed,
                overlay: !!overlay
            };
            wrapperClasses[direction] = true;

            const wrapperProps = {
                ref: 'wrapper',
                id: wixAdsId,
                className: coreUtils.classNames(wrapperClasses)
            };

            const wrapperChildren = [
                santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss)
            ];

            wrapperChildren.push(getWixAdsElement(this.props));

            return createReactElement('div', wrapperProps, wrapperChildren);
        }
    }
    BannerResponsive.displayName = 'WixFreemiumBannerResponsive';
    BannerResponsive.propTypes = {
        wixAdsId: PropTypes.string,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        metaSiteId: PropTypes.string,
        translations: PropTypes.object,
        direction: PropTypes.string,
        overlay: PropTypes.bool
    };
    return BannerResponsive;
});
