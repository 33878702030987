define([
    'lodash',
    'react',
    'coreUtils',
    'prop-types',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixCoBrandingAdsCss'
], function (_, React, coreUtils, PropTypes, santaComponents, translationsUtils, wixAdsCss) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;
    const toAbsoluteUrl = href => new RegExp('^https?://').test(href) ? href : `//${href}`;

    function getAdDesktopProps(href, wixAdsId, direction, overlay, isResponsive) {
        const classes = ['desktop-top'];
        const withLink = !overlay && href;

        if (overlay) {
            classes.push('overlay');
        } 
        
        if (withLink) {
            classes.push('with-link');
        }

        if (isResponsive) {
            classes.push('responsive');
        }

        classes.push(direction);

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`
        };

        if (withLink) {
            _.assign(props, {
                href: toAbsoluteUrl(href),
                'target': '_blank',
                'rel': 'nofollow'
            });
        }

        return props;
    }

    function getAdMobileProps(href, wixAdsId, isHeaderFixedPosition, direction, overlay) {
        const classes = ['mobile-top'];
        const withLink = !overlay && href;

        if (overlay) {
            classes.push('overlay');
        } 
            
        if (withLink) {
            classes.push('with-link');
        }

        if (isHeaderFixedPosition) {
            classes.push('fixed-ad-mobile');
        }

        classes.push(direction);

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'mobileTop',
            'data-aid': `${wixAdsId}mobileTop`
        };

        if (withLink) {
            _.assign(props, {
                href: toAbsoluteUrl(href),
                'target': '_blank',
                'rel': 'nofollow'
            });
        }
        return props;
    }

    function getBrandLogoString(brandLogoUrl) {
        return `<img src="${brandLogoUrl}" class="brand-logo"/>`;
    }

    function getBrandLogoElement(brandLogoUrl) {
        return createReactElement('img', {
            className: 'brand-logo',
            src: brandLogoUrl
        });
    }

    function getBrandFullText(bannerText, brandName, direction, brandLogoUrl) {
        const brandNameClasses = ['brand-name'];
        if (brandLogoUrl) {
            brandNameClasses.push('with-link');
        }

        return createReactElement('div', {className: `text-container ${direction}`},
            createReactElement('span', {}, `${bannerText} `),
            createReactElement('span', {className: brandNameClasses.join(' ')}, brandName));
    }

    function getWixAdsElement(props) {
        const {
            wixAdsId,
            isMobileView,
            isHeaderFixedPosition,
            overlay,
            direction,
            brandName,
            brandSiteUrl,
            brandLogoUrl,
            translations,
            isResponsive
        } = props;

        if (overlay) {
            return createReactElement('div', isMobileView ?
                getAdMobileProps(null, wixAdsId, isHeaderFixedPosition, direction, overlay) :
                getAdDesktopProps(null, wixAdsId, direction, overlay, isResponsive));
        }

        const brandLogoString = getBrandLogoString(brandLogoUrl);
        const adsTranslation = translationsUtils.getWixCoBrandingTranslations(translations, brandLogoString, 'Wix_Ads_Co_Branding_Top_Banner', 'Wix_Ads_Mobile_Co_Branding_Top_Banner');
        const rootElement = brandLogoUrl ? 'a' : 'div';
        return isMobileView ?
            createReactElement(rootElement,
                getAdMobileProps(brandSiteUrl, wixAdsId, isHeaderFixedPosition, direction),
                adsTranslation.mobileBanner) :
            createReactElement(rootElement,
                getAdDesktopProps(brandSiteUrl, wixAdsId, direction, null, isResponsive),
                createReactElement('div', {className: `logo-container ${direction}`}, getBrandLogoElement(brandLogoUrl)),
                getBrandFullText(adsTranslation.desktopBanner, brandName, direction, brandLogoUrl)
            );
    }

    class WixCoBrandingBanner extends React.Component {
        render() {
            const {wixAdsId, isMobileView, isWixAdsAllowed} = this.props;

            const wrapperClasses = {
                'wix-co-branding-banner': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed,
                mobile: isMobileView,
                desktop: !isMobileView
            };

            const wrapperProps = {
                ref: 'wrapper',
                id: wixAdsId,
                className: coreUtils.classNames(wrapperClasses)
            };

            const wrapperChildren = [
                santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss)
            ];

            wrapperChildren.push(getWixAdsElement(this.props));

            return createReactElement('div', wrapperProps, wrapperChildren);
        }
    }
    WixCoBrandingBanner.displayName = 'WixCoBrandingBanner';
    WixCoBrandingBanner.propTypes = {
        wixAdsId: PropTypes.string,
        isMobileView: PropTypes.bool,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        language: PropTypes.string,
        metaSiteId: PropTypes.string,
        isHeaderFixedPosition: PropTypes.bool,
        translations: PropTypes.object,
        isWixAdsForOneApp: PropTypes.bool,
        reportBI: PropTypes.func,
        overlay: PropTypes.bool,
        direction: PropTypes.string,
        brandSiteUrl: PropTypes.string,
        brandName: PropTypes.string,
        brandLogoUrl: PropTypes.string,
        isResponsive: PropTypes.bool
    };
    return WixCoBrandingBanner;
});
