define([
    'lodash',
    'react',
    'coreUtils',
    'prop-types',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixAdsCss',
    'wixFreemiumBanner/bi/events.json'
], function (_, React, coreUtils, PropTypes, santaComponents, translationsUtils, wixAdsCss, biEvents) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;

    function getAdDesktopProps({
        isPreview,
        href,
        wixAdsId,
        direction,
        overlay,
        isHighlighted
    }) {
        const classes = ['desktop-top', direction];

        if (isPreview) {
            classes.push('preview');
        }

        if (overlay) {
            classes.push('overlay');
        }

        if (isHighlighted) {
            classes.push('highlighted');
        }

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`,
            style: {height: 50, display: 'flex'}
        };

        if (href) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }
        return props;
    }

    function getAdMobileProps({
        isPreview,
        href,
        wixAdsId,
        direction,
        overlay,
        isHighlighted,
        isHeaderFixedPosition
    }) {
        const classes = ['mobile-top', direction];

        if (isPreview) {
            classes.push('preview');
        }

        if (overlay) {
            classes.push('overlay');
        }

        if (isHighlighted) {
            classes.push('highlighted');
        }

        if (isHeaderFixedPosition) {
            classes.push('fixed-ad-mobile');
        }

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'mobileTop',
            'data-aid': `${wixAdsId}mobileTop`,
            style: {height: 38, display: 'flex'}
        };

        if (href) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }
        return props;
    }

    const isWixAdsForOneApp = props => props.isWixAdsForOneApp && props.isMobileView && !props.isPreview;

    function getOneAppWixAdsElement(props, adsTranslation) {
        const {
            wixAdsId,
            isHeaderFixedPosition,
            direction,
            reportBI
        } = props;

        const mobileProps = getAdMobileProps({
            isPreview: false,
            href: adsTranslation.oneAppUrl,
            wixAdsId,
            direction,
            overlay: false,
            isHeaderFixedPosition
        });

        const onOneAppAdClick = () => {
            reportBI(biEvents.ONE_APP_FREE_BANNER_CLICK, {});
        };

        return createReactElement(
            'a',
            Object.assign({}, mobileProps, {
                className: `${mobileProps.className} one-app-banner`,
                onClick: onOneAppAdClick
            }),
            adsTranslation.oneAppBanner
        );
    }

    function getOverlayWixAdsElement(props) {
        const {
            wixAdsId,
            isMobileView,
            isPreview,
            isHeaderFixedPosition,
            overlay,
            direction
        } = props;

        return createReactElement('div', isMobileView ?
            getAdMobileProps({
                isPreview,
                href: null,
                wixAdsId,
                direction,
                overlay,
                isHeaderFixedPosition
            }) :
            getAdDesktopProps({
                isPreview,
                href: null,
                wixAdsId,
                direction,
                overlay
            })
        );
    }

    function getTopBarRedesignWixAdsElement(props, adsTranslation) {
        const {
            wixAdsId,
            isMobileView,
            isHeaderFixedPosition,
            direction
        } = props;

        return isMobileView ?
            createReactElement(
                'a',
                getAdMobileProps({
                    isPreview: true,
                    href: adsTranslation.desktopUrl2,
                    wixAdsId,
                    direction,
                    isHighlighted: true,
                    isHeaderFixedPosition
                }),
                createReactElement('span', {className: 'content'}, createReactElement('span', {'className': `text-button ${direction}`}, adsTranslation.mobileBannerTopBarButton), adsTranslation.mobileBannerTopBarText)
            ) :
            createReactElement(
                'a',
                getAdDesktopProps({
                    isPreview: true,
                    href: adsTranslation.desktopUrl2,
                    wixAdsId,
                    direction,
                    isHighlighted: true
                }),
                createReactElement('span', {className: 'contents'}, adsTranslation.desktopBanner2Text, createReactElement('span', {'className': `button ${direction}`}, adsTranslation.desktopBanner2Button))
            );
    }


    function getRegularWixAdsElement(props, adsTranslation) {
        const {
            wixAdsId,
            isPreview,
            isMobileView,
            isHeaderFixedPosition,
            direction
        } = props;

        return isMobileView ?
            createReactElement(
                isPreview ? 'div' : 'a',
                getAdMobileProps({
                    isPreview,
                    href: isPreview ? null : adsTranslation.mobileUrl2,
                    wixAdsId,
                    direction,
                    isHeaderFixedPosition
                }),
                adsTranslation.mobileBanner2
            ) :
            createReactElement(
                'a',
                getAdDesktopProps({
                    isPreview,
                    href: adsTranslation.desktopUrl2,
                    wixAdsId,
                    direction
                }),
                createReactElement('span', {className: 'contents'}, adsTranslation.desktopBanner2Text, createReactElement('span', {'className': `button ${direction}`}, adsTranslation.desktopBanner2Button))
            );
    }

    class Banner2 extends React.Component {
        componentDidMount() {
            if (isWixAdsForOneApp(this.props)) {
                this.props.reportBI(biEvents.ONE_APP_FREE_BANNER_SHOWN, {});
            }
        }
        getAdsElement() {
            const {
                isPreview,
                metaSiteId,
                translations,
                overlay,
                isWixAdsForTopBarRedesign
            } = this.props;

            const adsTranslation = translationsUtils.getWixFreemiumTranslations(translations, isPreview, metaSiteId);
            adsTranslation.desktopUrl2 = `${adsTranslation.desktopUrl2}&orig_msid=${metaSiteId}`;
            adsTranslation.mobileUrl2 = `${adsTranslation.mobileUrl2}&orig_msid=${metaSiteId}`;

            if (overlay) {
                return getOverlayWixAdsElement(this.props);
            }

            if (isWixAdsForOneApp(this.props)) {
                return getOneAppWixAdsElement(this.props, adsTranslation);
            }

            if (isPreview && isWixAdsForTopBarRedesign) {
                return getTopBarRedesignWixAdsElement(this.props, adsTranslation);
            }

            return getRegularWixAdsElement(this.props, adsTranslation);
        }
        render() {
            const {wixAdsId, isMobileView, isWixAdsAllowed} = this.props;

            const wrapperClasses = {
                'wix-ads-2': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed,
                mobile: isMobileView,
                desktop: !isMobileView
            };

            const wrapperProps = {
                ref: 'wrapper',
                id: wixAdsId,
                className: coreUtils.classNames(wrapperClasses)
            };

            const wrapperChildren = [
                santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss)
            ];

            wrapperChildren.push(this.getAdsElement());

            return createReactElement('div', wrapperProps, wrapperChildren);
        }
    }
    Banner2.displayName = 'WixFreemiumBanner2';
    Banner2.propTypes = {
        wixAdsId: PropTypes.string,
        isMobileView: PropTypes.bool,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        language: PropTypes.string,
        metaSiteId: PropTypes.string,
        isHeaderFixedPosition: PropTypes.bool,
        translations: PropTypes.object,
        isWixAdsForOneApp: PropTypes.bool,
        isWixAdsForTopBarRedesign: PropTypes.bool,
        reportBI: PropTypes.func,
        overlay: PropTypes.bool,
        direction: PropTypes.string
    };
    return Banner2;
});
